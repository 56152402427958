$cartus-palette: (
  cartus_white: #ffffff,
  cartus_black: #000,
  cartus_red: #d61313,
  cartus_blue: #012069,
  cartus_light_blue: #2f8cdc,
  cartus_primary_blue: #122455,
  cartus_gray_medium: #707070,
  cartus_layout_container: #eff3f6,
  cartus_footer_link: #122855,
  cartus_input_border:#b8bec5,
  //   cartus_blue: #4f85c4,
  cartus_accent_blue_disabled: #a6c2ff,
  cartus_accent_blue1: #4b85ff,
  cartus_gray: #575a5d,
  cartus_dark_navy: #173159,
  //   cartus_light_blue: #cce1ef,
  cartus_light_warm_gray: #eeece9,
  cartus_sky: #88b2d8,
  cartus_mint: #00838f,
  //   cartus_primary_blue: #3c72b9,
  cartus_primary_blue_contrast: #ffffff,
  cartus_primary_dark_blue: #173159,
  cartus_primary_mint: #a5e5d9,
  cartus_primary_dark_brown: #575a5d,
  cartus_secondary_gray: #a5acb0,
  cartus_seconday_off_white: #eeece9,
  cartus_secondary_baby_blue: #88b2d8,
  cartus_secodary_light_blue: #cce1ef,
  cartus_notification_icon_green: #048500,
  cartus_menu_divider: #666666,
  cartus_nav_tile_hover: #ecf3f9,
  cartus_nav_tile: #ffffff,
  cartus_alt_text: #696969,
  cartus_link_color: #3c72b9,
  cartus_mint_2: #d2f2ec,
  cartus_seconday_off_white_2: #f3f3f3,
  cartus_tile_footer_background: #ffffff,
  cartus_hover_background_color: #d4e0f0,
  cartus_accent_blue: #3c72b9,
  cartus_dark_accent_blue: #002f82,
  cartus_disabled_gray: #aaaaaa,
  cartus_light_gray: #949494,
  cartus_orange_tint: #fde8d6,
  cartus_hover_tint: #edf4d9,
  cartus_error_feedback: #d60000,
  cartus_pale_gray: #cccccc,
  cartus_warning_color: #ea6812,
  cartus_highlight: #ffff00,
  cartus_couple_color: #004e8b,
  cartus_family_color: #00275d,
  cartus_search_box_color: #f0f0f0,
  cartus_person_color: #3e79bb,
  cartus_username_color: #4d4f5c,
  cartus_footer_text_color: #787878,
  cartus_privacy_background_color: #f7f7f7,
);
