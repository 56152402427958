@import '../sass/cartus-palette.scss';
@import '../sass/button-styles.scss';
@import '../sass/dialog.scss';
@import '../sass/mixins/media-mixins.scss';
html {
  height: 100%;
}

// Mixins function for width

$precentage-numbers: (
  width25: 25%,
  width33: 33%,
  width50: 50%,
  width75: 75%,
  width100: 100%,
);
@each $precentage-var, $precentage in $precentage-numbers {
  &.#{$precentage-var} {
    // width: #{$precentage};
    flex: 0 0 #{$precentage};
    max-width: #{$precentage};
    @include respond-below(md) {
      float: 0 0 100%;
      max-width: 100%;
    }
  }
}

//Mixins functions for show/hide class for different devices/resolutions

$devices: (sm, md, lg, xl);
@each $media in $devices {
  .show-#{$media} {
    display: none;
    @include respond-below(#{$media}) {
      display: block !important;
    }
  }
  .hidden-#{$media} {
    display: block;
    @include respond-below(#{$media}) {
      display: none !important;
    }
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  [class^='width'] {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
}
.error {
  border: 1px solid map-get($cartus-palette, 'cartus_red');
  &-message {
    color: map-get($cartus-palette, 'cartus_red');
  }
}

p {
  font-family: 'Roboto', sans-serif;
  font-size: 1em;
  line-height: 1.5em;
}

/* Cartus Design Framework Documenation */

/* Material Design - Typography */

h1 {
  font-size: 1.5em;
  color: map-get($cartus-palette, 'cartus_gray');
}

h2 {
  font-size: 1.25em;
  color: map-get($cartus-palette, 'cartus_gray');
}
h4 {
  font-size: 34px;
}
h5 {
  font-size: 24px;
}
input.mat-input-element {
  margin-top: 0.4em;
}
.mat-form-field-flex{
  height:52px !important;
}
.mat-form-field-appearance-outline .mat-select-arrow-wrapper{
  transform: translateY(25%) !important;
}
.mat-select-value{
  padding-top: 4px;
}
mat-label{
  span{
    color: map-get($cartus-palette, 'cartus_red');
    font-size: 15px;
  }
}
.required-fields{
  color: map-get($cartus-palette, 'cartus_red');
  margin-left: 20px;
  margin-top: 3px;
  font-size: 15px;
  font-family: 'Roboto-Light';
}
.form-heading{
  display: flex;
}
.form-question{
  color: map-get($cartus-palette, 'cartus_blue');
  margin-bottom: 15px;
  font-size: 15px;
}
.mat-form-field-appearance-outline{
  .mat-form-field-wrapper{
    margin:0.6em 0;
}
.mat-form-field-infix{
  padding: 0.5em 0 0.5em 0 !important;
}
}
.mat-form-field {
  margin-bottom: 20px;
}

/* Material Design - Buttons & Links */

/* Material Design - Tables */

.mat-paginator-container {
  position: relative;

  .mat-paginator-page-size {
    position: relative;
    right: 0;
  }

  .mat-paginator-range-actions {
    position: relative;
    padding-left: 40px;

    .mat-paginator-range-label {
      margin: 0;
      position: static;
      left: 0;
      font-size: 0.75em;
      font-family: 'Roboto', sans-serif;
      padding-top: 5px;
    }

    .mat-paginator-navigation-previous {
      margin-right: auto;
      position: absolute;
      left: 0;
    }
  }
}

.mat-paginator-page-size-select {
  width: 2.8125em;
  padding-top: 0.2em;
}
/* Material Design - Forms, Validations, Error */

.mat-form-field-appearance-outline {
 .mat-form-field-subscript-wrapper{
  padding: 0 0.5em !important;
 }
  .mat-form-field-outline-end,
  .mat-form-field-outline-gap,
  .mat-form-field-outline-start {
    border-color: #b8bec5;
  }
}

.mat-error {
  color: map-get($cartus-palette, 'cartus_red');
  font-size: 15px;
  font-family: 'Roboto-Light';
}

/* Material Design - Lists */

/* Material Design - Icons */

.page_header .mat-icon {
  color: map-get($cartus-palette, 'cartus_light_gray') !important;
  vertical-align: bottom;
  cursor: pointer;
}

/* Material Design - Notifications */

.success {
  background-color: map-get($cartus-palette, 'cartus_notification_icon_green');
}

.success button {
  color: #fff;
}

.danger {
  background-color: map-get($cartus-palette, 'cartus_error_feedback');
}

.danger button {
  color: #fff;
}

.mat-dialog-title {
  margin: 0 !important;
}

.mat-dialog-content {
  padding: 0.5em 1.5em !important;
}

.mat-dialog-actions {
  justify-content: flex-end;
}

//popup container style ends here
//auto complete
.mat-autocomplete-panel {
  .mat-option,
  .mat-select-value {
    font-family: 'Roboto', sans-serif;
    font-size: 1em !important;
    line-height: 2.5em !important;
    color: #565656 !important;
    @media screen and (max-width: 600px) {
      font-family: 'Roboto-Medium', sans-serif;
    }
  }
}
//@at-root
@media screen and (max-width: 767px) {
  .hide-xs {
    display: none;
  }
}

.uppercase {
  text-transform: uppercase;
}

.strong {
  font-family: 'Roboto-Black', sans-serif !important;
}

.italic {
  font-family: 'Roboto-Oblique', sans-serif !important;
}

.clickable {
  cursor: pointer;
}
