@import '~@angular/material/theming';

// Always include only once per project
@include mat-core();

// Import our custom theme
@import 'sass/app-theme.scss';
@import 'sass/fonts.scss';
@import 'sass/cartus-palette.scss';
@import 'sass/app.scss';
@import 'sass/common-styles.scss';
@import 'sass/dialog.scss';
@import 'sass/snackbar.scss';
@import 'sass/mat-table.scss';
@import 'sass/mat-table-column.scss';
@import 'sass/breadcrum.scss';
@import 'sass/mixins/media-mixins.scss';

@mixin custom-components-theme($theme, $typography, $cartus-palette) {
  @include app($theme, $typography, $cartus-palette);
}

@include angular-material-theme($app-theme);
@include custom-components-theme($app-theme, $typography, $cartus-palette);

// .ccu-theme {
//   // use our theme with angular-material-theme mixin
//   // @include angular-material-theme($app-theme);
//   // include custom components theme mixin once per theme class
//   // @include custom-components-theme($app-theme, $typography, $cartus-palette);
// }

html,
body {
  height: 100%;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  // background: url('./assets/imgs/dash.png') 0 0 no-repeat;
  // background: url(./assets/imgs/dash.png) no-repeat center center fixed;
  // background-image: url('./assets/imgs/dash.png');
  background-color: map-get($cartus-palette, 'cartus_layout_container');
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: map-get($cartus-palette, 'cartus_black');
  -moz-osx-font-smoothing: grayscale;
  /* Fix blurry font for Firefox Mac users connected to external displays */
}

*[fxlayoutgap] > *:last-child {
  margin-right: 0;
  /* Fix for flex last column in a row having a right margin */
}

.btn-primary {
  background: map-get($cartus-palette, 'cartus_footer_link');
  margin: auto;
  text-align: center;
  color: map-get($cartus-palette, 'cartus_white');
  border: 0;
  display: table;
  /* height: 46px; */
  padding: 11px 27px;
  /* width: 244px; */
  font-size: 20px;
  font-family: 'Roboto-Light', sans-serif;
  font-weight: 300;
  border-radius: 4px;
  margin-bottom: 40px;
  @include respond-below(md) {
    margin: 8px auto 20px;
    display: block;
    width: 97%;
    padding: 10px;
  }
}
.disclaimer-wrapper {
  font-size: 15px;
  font-family: 'Roboto-Light', sans-serif;
  font-weight: 300;
  padding: 0px 60px 56px 40px;
  margin-bottom: 48px;
  @include respond-below(md) {
    padding: 0px 10% 56px;
  }
}
.create-referral {
  padding: 2em;
}

.statusBtn {
  .submit {
    background: #58cc6b;
    color: #fff;
  }

  .save {
    background: #f4a814;
    color: #fff;
  }

  .clear {
    background: #e5173e;
    color: #fff;
  }
}

.rightMain {
  .mat-form-field {
    width: 22em;
  }
}

.agent-form-section {
  margin-left: 40px;
  margin-right: 58px;
  margin-top: 26px;
  margin-bottom: 100px;

  .input-field {
    width: 384px;
  }

  .input-field-form {
    margin-left: 18px;
    margin-right: 12px;
  }

  .address-input-field {
    width: 803px;

    @include respond-below(md) {
      width: 100%;
    }
  }

  .input-mobile-field {
    width: 122px;
    margin-left: 20px;
    font-weight: 100;
  }

  .input-phone-field {
    width: 244px;
  }

  .state-zip-input-field {
    width: 183px;
    margin-right: 22px;
  }
  @include respond-below(xl) {
    margin: 20px 20px 100px;
  }
  @include respond-below(md) {
    margin: 0 0 100px;
  }
}

.mat-button-field {
  width: 112px;
  padding: 4px 4px;
  margin-right: 20px !important;
  border: 1px solid #b8bec5;
  font-size: 18px !important;
}

.mat-button-toggle-appearance-standard {
  .mat-button-toggle-label-content {
    line-height: 38px;
  }
}
.mat-select {
  font-family: 'Roboto-Light' !important;
}
.mat-form-field-infix {
  font-family: 'Roboto-Light' !important;
}
.mat-form-field-label {
  font-size: 15px !important;
  color: map-get($cartus-palette, 'cartus_black');
  font-family: 'Roboto-Light' !important;
}
.mat-stroked-button:not(.mat-button-disabled) {
  border: 1px solid #b8bec5;
}
// .mat-form-field-appearance-outline {
//   .mat-form-field-outline{
//     color:map-get($cartus-palette, 'cartus_input_border');
// }

// }

.customerPreference-select {
  color: white;
  background-color: map-get($cartus-palette, 'cartus_primary_blue');
  font-family: 'Roboto', sans-serif !important;
  font-weight: bold;
}

.customerPreference-unselect {
  color: map-get($cartus-palette, 'cartus_black');
  background-color: transparent;
  font-family: 'Roboto-Light' !important;
}
.mobile-field {
  display: flex;
}
.mat-form-field-appearance-outline {
  .mat-form-field-outline-start {
    border-radius: 4px 0 0 4px;
  }
}
.mat-form-field.mat-form-field-invalid {
  .mat-form-field-label {
    color: map-get($cartus-palette, 'cartus_black');
  }
}
input:-internal-autofill-selected {
  background-color: red !important;
}
.mat-form-field {
  font: 500 14px/1.1 Roboto, sans-serif !important;
}
// mat-input focused color
.mat-form-field-appearance-outline:not(.mat-form-field-disabled)
  .mat-form-field-flex
  .mat-form-field-outline {
  .mat-form-field-outline-start {
    border-top: 1px solid;
    border-left: 1px solid;
    border-bottom: 1px solid;
  }

  .mat-form-field-outline-gap {
    border-bottom: 1px solid;
  }

  .mat-form-field-outline-end {
    border-top: 1px solid;
    border-right: 1px solid;
    border-bottom: 1px solid;
  }
}
@media screen and (max-width: 567px) {
  .agent-form-section {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;

    .input-field {
      width: 305px;
    }
    .address-input-field {
      width: 305px;
    }
    .input-phone-field {
      width: 184px;
    }
    .input-mobile-field {
      width: 100px;
    }
    .state-zip-input-field {
      width: 142px;
      margin-right: 20px;
    }
    .state-zip-input-field:last-child {
      margin-right: 0px;
    }
    .mat-button-field {
      width: 92px;
      margin-right: 12px !important;
    }
  }
}
@media screen and(min-width:568px) {
  .agent-form-section {
    .address-input-field {
      width: 244px;
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .agent-form-section {
    .address-input-field {
      width: 387px;
    }
    .state-zip-input-field {
      margin-right: 20px;
    }
    .state-zip-input-field:last-child {
      margin-right: 0px;
    }
    .mat-button-field:last-child {
      margin-right: 0px !important;
    }
    .mat-button-field {
      margin-right: 22px !important;
    }
  }
}
@media screen and (min-width: 1024px) {
  .agent-form-section {
    .address-input-field {
      width: 800px;
    }
  }
}

/*******
***Left side navigation menu style
*******/
.left-nav-wrapper-menus {
  padding: 13px 10px 13px 22px;
  list-style: none;
  li {
    padding: 0;
    margin-bottom: 23px;
    a {
      color: map-get($cartus-palette, 'cartus_white');
      text-decoration: none;
      cursor: pointer;
      font-size: 14px;
      display: flex;
      align-items: center;
      font-weight: 400;
      .mat-icon {
        font-size: 23px;
        height: auto;
        width: auto;
        margin-right: 7px;
      }
      &:hover,
      &.active {
        font-family: 'Roboto-Bold';
        font-weight: 700;
      }
    }
  }
  @include respond-below(md) {
    padding: 35px 30px;
    margin: 0;
    li {
      a {
        font-size: 18px;
        .mat-icon {
          font-size: 20px;
          margin-right: 15px;
        }
      }
    }
  }
}
